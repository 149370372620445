import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import ActionsMain from "../components/actionsMain";

const Actions = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const magazinPreview = data && data?.magazinPreview?.nodes;
  const allServices = data && data?.allServices?.nodes;
  const actionsData = data && data?.actions?.actions;
  const tags = data && data?.tags?.nodes;
  return (
    <Layout services={allServices} tags={tags} magazin={magazinPreview}>
      <SEO
        title={"Akce"}
        description={"Aktuální akce na Beauty Body Clinic."}
        keywords={["Akce", "Sleva"]}
      />
      <PageContainer>
        <ActionsMain actionsData={actionsData} />
      </PageContainer>
    </Layout>
  );
};

export default Actions;
