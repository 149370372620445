import React, { useEffect, useState } from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";
import Icon from "./icon/icon";
import PortableText from "./portableText";
import FadeInSection from "./FadeIn";

import * as styles from "./actionsMain.module.css";
import * as commonStyles from "../styles/common.module.css";

function ActionsMain({ actionsData = [] }) {
  const [actionsActive, setActive] = useState([]);

  const activateIcons = () => {
    setActive([true, false, false]);
    setTimeout(() => {
      setActive([true, true, false]);
    }, 400);
    setTimeout(() => {
      setActive([true, true, true]);
    }, 800);
  };

  useEffect(() => {
    if (!actionsActive[0]) activateIcons();
  }, [actionsActive]);

  return (
    <div>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Akce
      </h1>
      <ul>
        {actionsData.map(({ action }, index) => (
          <li key={`action_${index}`}>
            <FadeInSection>
              <Link to={`/konzultace?${encodeURI(action.title)}`}>
                <div className={styles.actionContainer}>
                  {action.icon !== null && (
                    <Icon type={action.icon} active={actionsActive[index]} width={"48px"} />
                  )}
                  <h2
                    className={cn(
                      styles.title,
                      commonStyles.colorAccent,
                      commonStyles.fontButlerMedium
                    )}
                  >
                    {action.title}
                  </h2>
                  <div
                    className={cn(styles.body, commonStyles.colorDarkGray, commonStyles.fontButler)}
                  >
                    <PortableText blocks={action._rawBody} />
                  </div>
                  <div
                    className={cn(
                      styles.priceBefore,
                      commonStyles.colorDarkGray,
                      commonStyles.fontButler
                    )}
                  >
                    {action.oldPrice}
                  </div>
                  <div
                    className={cn(styles.price, commonStyles.colorAccent, commonStyles.fontButler)}
                  >
                    {action.newPrice}
                  </div>
                  {action.addition !== null && (
                    <div
                      className={cn(
                        "uppercase",
                        commonStyles.colorDarkGray,
                        commonStyles.fontReleway
                      )}
                    >
                      {action.addition}
                    </div>
                  )}
                </div>
              </Link>
            </FadeInSection>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ActionsMain;
